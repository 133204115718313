export const apiEndPoints = {
  // Home Routes //
  getHomePageSliderItems: "/home/getSliderItems",
  getHomePageScrollerItems: "/home/getScrollerItems",
  getHomePageSpotlightItems: "/home/getSpotlightItems",

  //to be further segrigates
  getHomePageDonationItems: "/home/getAllDonationItems",
  getAnnualCalender: "/home/getAnnualCalender",
  getHomePageServiceCatogryItems: "/home/getAllServiceCatogeryItems",
  getHomePagePanchang: "/home/getPanchang",
  getHomePageUpcomingEvents: "/home/getUpcomingEvents",
  getHomePageLatesNewsItems: "/home/getLatestNews",
  getHomePageLatesEventItems: "/home/getLatestEvents",
  getHomePagetodaysPujaSchedule: "/home/getTodaysPujaSchedule",
  getallNews: "/home/get_all_news",
  getliveStreamingUrl: "/home/get_live_streaming",
  getMonthlyNewsletter: "/home/get_monthly_newsletter",
  getAllNewsForTvGallery: "/home/getAllNewsForTvGallery",
  getIndividualNews: "/home/get_news_detail",

  // eventsAndActivities Routes //
  getEventDetailsBySlug: "/eventsAndActivies/getEventDetailsBySlug",
  getAllPaginatedEvents: "/eventsAndActivies/getAllPaginatedEvents",
  balGokulBooking: "/eventsAndActivies/baalgokulorder/submit",
  getBalGokul: "/eventsAndActivies/baalgokul/list",
  allMeraApne: "/eventsAndActivies/mereapne/getAll",
  getUpcomingEvents: "/eventsAndActivies/getUpcomingEvents",
  allPbj: "/eventsAndActivies/pbj/getAll",
  allYouthTruth: "/eventsAndActivies/youthtruth/getAll",
  get_tvgallery_events: "/eventsAndActivies/get_tvgallery_events",

  // services Routes //
  getAllServiceCatogryItems: "/services/getAllServiceCatogeryItems",
  getAllServicesAtTempleByServicesSlug:
    "/services/getAllServicesAtTempleByServicesSlug",
  getServicesOutsideTemple: "/services/getServicesOutsideTemple",
  serviceDetails: "/services/get_service_detail",
  getRental: "/services/get_rental",
  getRentalPurpose: "/services/rentals/purposes",
  allPujaSamagri: "/services/pujasamagri/getAll",
  getAllCateringItems: "/services/getAll_cateringItems",
  getAllCafeteriaItems: "/services/get_cafeteriaItems",
  getSpecificService: "/services/get_specific_service",

  // common Routes //
  nakshatram: "/common/get_nakshatram",
  nakshatrambyrashi: "/common/get_nakshatram_rashi",
  rashi: "/common/get_rashi",
  rashibynakshatramid: "/common/get_rashi_nakshatram",
  languages: "/common/get_language",
  countries: "/common/get_country",
  state: "/common/get_state",
  getPriests: "/common/priest/getAll",
  getSearchData: "/common/searchbar/getall",
  getTempleConfigByCode: "/common/getTempleConfigByCode",
  getBookingConfigByTitle: "/common/getBookingConfigByTitle",
  // user route //
  getProfile: "/user/get_profile",
  devoteeUpdate: "/user/update_profile",
  changePassword: "/user/change_password",
  contactUs: "/user/contact-us/store",
  volunteer: "/user/volunteer/add",
  registerOrderDetails: "/user/registerOrderDetails",
  addSankalp: "/user/add_sankalp",
  checkAvailability: "/user/check_availability",
  sendRejectionMail: "/user/send_rejection",
  getBookingData: "/user/get_booking",
  getOrderDetails: "/user/get_order_detail",
  getReportDetail: "/user/get_report_detail",
  sendReportMail: "/user/send_report_mail",

  //auth router
  devoteeRegister: "/auth/register",
  devoteeLogin: "/auth/login",
  forgotPassword: "/auth/forgot_password",
  resetPassword: "/auth/reset_password",
  isValidResetToken: "/auth/is_valid_reset_token",
  subscribe: "/auth/subscribe",
  verifyNewsLetter: "/auth/verify_news_letter_otp",

  // gallery routes //
  galleryCategories: "/gallery/get_gallery_categories",
  galleryFestivals: "/gallery/get_gallery_festivals",
  galleryTags: "/gallery/get_all_gallery_tags",
  allGallery: "/gallery/get_all_gallery",
  galleryPhotos: "/gallery/get_gallery_photos",
  galleryVideos: "/gallery/get_gallery_videos",
  getAllPlaylist: "/gallery/get_all_playlist",
  allReligiousBooks: "/gallery/religiousbooks/getBooks",
  getAllbooksLanguages: "/gallery/booksLanguages/getAll",
  getAllbooksCategory: "/gallery/booksCategory/getAll",

  // payment routes //
  stripePayment: "/payment/make_stripe_payment",

  //donation routes
  getAllDonations: "/donation/get_donation",
  getDonationByCategory: "/donation/get_donation_detail",

  // createReoccurigDonationSubscription: "reoccuring_donation",
  // recurringDonationPayment: "make_stripe_payment_for_recurringdonation",
  // createReoccurigDonationSubscription: "reoccuring_donation",
  // balGokulPayment: "make_stripe_payment_For_BalGokul",
};

export const galleryType = {
  photo: "photo",
  video: "video",
};
