import "./Videoplaylist.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";

import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./gallery.css";
import { Link } from "react-router-dom";
import { apiEndPoints } from "../../ApiServices/config";
import Masonry from "@mui/lab/Masonry";
import { getApi, postApi } from "../../ApiServices/api";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function VideoPlaylist() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [slug, setSlug] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [galleryVideos, setGalleryVideos] = React.useState([]);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [gallery, setGallery] = useState({
    list: [],
    categories: [],
    festival: [],
    tags: [],
    playlist: [],
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue === "3" ? "shorts" : "");
    setValue(newValue);
    setPlaylistId("");
    setSlug("");
    setSearch("");
  };

  const payload = {
    keyword: "",
    category: "",
    festival: "",
    tags: "",
    order: "asc",
    type: "video",
  };

  const getEvent = async () => {
    const [
      galleryList,
      galleryCategories,
      galleryFestivals,
      galleryTags,
      playlistData,
    ] = await Promise.all([
      postApi(apiEndPoints.allGallery, payload),
      getApi(apiEndPoints.galleryCategories),
      getApi(apiEndPoints.galleryFestivals),
      getApi(apiEndPoints.galleryTags),
      getApi(apiEndPoints.getAllPlaylist),
    ]);

    setGallery({
      list: galleryList?.data?.status == 1 ? galleryList?.data?.data : [],
      categories:
        galleryCategories?.data?.status == 1
          ? galleryCategories?.data?.data
          : [],
      festival:
        galleryFestivals?.data?.status == 1 ? galleryFestivals?.data?.data : [],
      tags: galleryTags?.data?.status == 1 ? galleryTags?.data?.data : [],
      playlist: playlistData?.status === 200 ? playlistData?.data?.data : [],
    });
  };

  const getPhotos = async () => {
    setIsLoading(true);
    const payload = {
      slug: slug,
      search: search,
      playlistId: playlistId,
      type: type,
    };
    const getVideos = await postApi(apiEndPoints.galleryVideos, payload);
    getVideos?.data?.status == 1
      ? setGalleryVideos(getVideos?.data?.data?.videos)
      : setGalleryVideos([]);

    setIsLoading(false);
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    if (value === "1" || value === "3" || slug || playlistId) {
      getPhotos();
    }
  }, [slug, value, playlistId]);

  return (
    <>
      {console.log("value : ", value)}
      <div className="container-fluid py-5">
        <div className="container pt-4">
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <IconButton>
            <Search
              onClick={() => {
                getPhotos();
              }}
              color="error"
            />
          </IconButton>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box>
                <TabList
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab label="Videos" value="1" className="tab-btn" />
                  <Tab label="Playlist" value="2" className="tab-btn" />
                  <Tab label="Shorts" value="3" className="tab-btn" />
                  <Tab label="Gallery Videos" value="4" className="tab-btn" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box
                  component="ul"
                  sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
                >
                  <div className="container pt-4">
                    <Grid container flexWrap={"wrap"}>
                      {isLoading ? (
                        <Card
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            background: "none !important",
                            border: "none !important",
                          }}
                        >
                          <CircularProgress />
                        </Card>
                      ) : galleryVideos?.length > 0 ? (
                        galleryVideos.map((video) => {
                          try {
                            var iframeSrc = "";
                            const embedUrl = video.url;
                            const url = new URL(embedUrl);
                            if (video?.slug === "youtube") {
                              const videoId = url.searchParams.get("v");
                              if (!videoId) {
                                console.warn(
                                  "Invalid YouTube URL, missing video ID:",
                                  video.url
                                );
                                return null;
                              }
                              iframeSrc = `https://www.youtube.com/embed/${videoId}`;
                            } else if (video?.slug === "facebook") {
                              iframeSrc = `https://www.facebook.com/plugins/video.php?href=${url}`;
                            }

                            return (
                              <Grid lg={4} md={6} sm={12} p={1}>
                                <Card
                                  component="li"
                                  sx={{
                                    minWidth: 300,
                                    flexGrow: 1,
                                    background: "none !important",
                                    border: "none",
                                    borderRadius: "0",
                                  }}
                                >
                                  <CardCover
                                    sx={{
                                      position: "relative", // Ensure iframe is positioned correctly
                                      overflow: "hidden", // Prevent accidental overflow
                                    }}
                                  >
                                    <iframe
                                      width="560"
                                      height="315"
                                      src={iframeSrc} // Use the constructed embed URL
                                      title={video?.description}
                                      frameBorder="0"
                                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                      style={{
                                        display: "block", // Ensure proper rendering
                                        zIndex: 1, // Bring iframe to the front
                                        borderRadius: "0",
                                      }}
                                    ></iframe>
                                  </CardCover>
                                  <CardContent sx={{ textAlign: "center" }}>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "#555",
                                        padding: "1rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "normal",
                                        mb: 2,
                                      }}
                                    >
                                      {video?.description}
                                      Published at :{" "}
                                      {video?.publishedDate || " "}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          } catch (error) {
                            console.error(
                              "Error processing video URL:",
                              video.url,
                              "| Error:",
                              error
                            );
                            return null;
                          }
                        })
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            color: "#555",
                            marginTop: "1rem",
                          }}
                        >
                          Currently, there are no event videos. Please check in
                          the future for all updated event videos.
                        </p>
                      )}
                    </Grid>
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value="2">
                {playlistId != "" ? (
                  <>
                    <div className="container-fluid gallery-bg py-5">
                      <div className="container pt-4">
                        <Grid container flexWrap={"wrap"} spacing={2}>
                          {isLoading ? (
                            <Card
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                background: "none !important",
                                border: "none !important",
                              }}
                            >
                              <CircularProgress />
                            </Card>
                          ) : galleryVideos?.length > 0 ? (
                            galleryVideos.map((video) => {
                              try {
                                var iframeSrc = "";
                                const embedUrl = video.url;
                                const url = new URL(embedUrl);
                                if (video?.slug === "youtube") {
                                  const videoId = url.searchParams.get("v");
                                  if (!videoId) {
                                    console.warn(
                                      "Invalid YouTube URL, missing video ID:",
                                      video.url
                                    );
                                    return null;
                                  }
                                  iframeSrc = `https://www.youtube.com/embed/${videoId}`;
                                } else if (video?.slug === "facebook") {
                                  iframeSrc = `https://www.facebook.com/plugins/video.php?href=${url}`;
                                }

                                return (
                                  <Grid lg={4} md={6} sm={12} p={1}>
                                    <Card
                                      component="li"
                                      sx={{
                                        minWidth: 300,
                                        flexGrow: 1,
                                        background: "none !important",
                                        border: "none !important",
                                      }}
                                    >
                                      <CardCover sx={{ position: "relative" }}>
                                        <iframe
                                          width="560"
                                          height="315"
                                          src={iframeSrc}
                                          title={video?.description}
                                          frameborder="0"
                                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          style={{
                                            display: "block",
                                            zIndex: 1,
                                            borderRadius: "0",
                                          }}
                                        ></iframe>
                                      </CardCover>
                                      <CardContent sx={{ textAlign: "center" }}>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: "#555",
                                            padding: "1rem 0",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "normal",
                                            mb: 2,
                                          }}
                                        >
                                          {video?.description}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                );
                              } catch (error) {
                                console.error(
                                  "Error processing video URL:",
                                  video.url,
                                  "| Error:",
                                  error
                                );
                                return null; // Skip the video if there's an error
                              }
                            })
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                color: "#555",
                                marginTop: "1rem",
                              }}
                            >
                              Currently, there are no event videos. Please check
                              in the future for all updated event videos.
                            </p>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="container-fluid gallery-bg py-5">
                      <div className="container pt-4">
                        <Grid container justifyContent="center">
                          {gallery.playlist?.length > 0 ? (
                            <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                              {gallery.playlist?.map((event) => (
                                <Link
                                  key={event._id}
                                  component="div"
                                  onClick={() => setPlaylistId(event._id)}
                                  underline="none"
                                  sx={{
                                    display: "block",
                                    width: { xs: 240, sm: 280, md: 320 }, // Responsive width for different screen sizes
                                    margin: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    overflow: "hidden",
                                    textDecoration: "none",
                                    position: "relative", // Positioning relative to overlay text
                                    "&:hover": {
                                      boxShadow: 6,
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: 180,
                                      backgroundImage: `url(${event.image})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      position: "relative", // Positioning context for overlay
                                    }}
                                  >
                                    {/* Text overlay */}
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        bottom: 10, // Position text at the bottom of the image
                                        left: 0,
                                        right: 0,
                                        padding: 2,
                                        textAlign: "center",
                                        color: "white", // Text color on top of the image
                                        background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background for readability
                                        borderRadius: 1,
                                      }}
                                    >
                                      <Typography variant="h6" gutterBottom>
                                        {event.name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        align="center"
                                      >
                                        {event.count} videos
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Link>
                              ))}
                            </Masonry>
                          ) : (
                            "Currently there are no events gallery, please check in future for all updated event gallery."
                          )}
                        </Grid>
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value="3">
                <>
                  <div className="container-fluid gallery-bg py-5">
                    <div className="container pt-4">
                      <Grid container flexWrap={"wrap"} spacing={2}>
                        {isLoading ? (
                          <Card
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              background: "none !important",
                              border: "none !important",
                            }}
                          >
                            <CircularProgress />
                          </Card>
                        ) : galleryVideos?.length > 0 ? (
                          galleryVideos.map((video) => {
                            try {
                              var iframeSrc = "";
                              const embedUrl = video.url;
                              const url = new URL(embedUrl);
                              if (video?.slug === "youtube") {
                                const videoId = url.searchParams.get("v");
                                if (!videoId) {
                                  console.warn(
                                    "Invalid YouTube URL, missing video ID:",
                                    video.url
                                  );
                                  return null;
                                }
                                iframeSrc = `https://www.youtube.com/embed/${videoId}`;
                              } else if (video?.slug === "facebook") {
                                iframeSrc = `https://www.facebook.com/plugins/video.php?href=${url}`;
                              }

                              return (
                                <Grid lg={4} md={6} sm={12} p={1}>
                                  <Card
                                    component="li"
                                    sx={{
                                      minWidth: 300,
                                      flexGrow: 1,
                                      background: "none !important",
                                      border: "none !important",
                                    }}
                                  >
                                    <CardCover sx={{ position: "relative" }}>
                                      <iframe
                                        width="560"
                                        height="315"
                                        src={iframeSrc}
                                        title={video?.description}
                                        frameborder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{
                                          display: "block",
                                          zIndex: 1,
                                          borderRadius: "0",
                                        }}
                                      ></iframe>
                                    </CardCover>
                                    <CardContent sx={{ textAlign: "center" }}>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: "#555",
                                          padding: "1rem 0",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "normal",
                                          mb: 2,
                                        }}
                                      >
                                        {video?.description}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              );
                            } catch (error) {
                              console.error(
                                "Error processing video URL:",
                                video.url,
                                "| Error:",
                                error
                              );
                              return null; // Skip the video if there's an error
                            }
                          })
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              color: "#555",
                              marginTop: "1rem",
                            }}
                          >
                            Currently, there are no event videos. Please check
                            in the future for all updated event videos.
                          </p>
                        )}
                      </Grid>
                    </div>
                  </div>
                </>
              </TabPanel>
              <TabPanel value="4">
                {slug != "" ? (
                  <>
                    <div className="container-fluid gallery-bg py-5">
                      <div className="container pt-4">
                        <Grid container flexWrap={"wrap"} spacing={2}>
                          {isLoading ? (
                            <Card
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                background: "none !important",
                                border: "none !important",
                              }}
                            >
                              <CircularProgress />
                            </Card>
                          ) : galleryVideos?.length > 0 ? (
                            galleryVideos.map((video) => {
                              try {
                                var iframeSrc = "";
                                const embedUrl = video.url;
                                const url = new URL(embedUrl);
                                if (video?.slug === "youtube") {
                                  const videoId = url.searchParams.get("v");
                                  if (!videoId) {
                                    console.warn(
                                      "Invalid YouTube URL, missing video ID:",
                                      video.url
                                    );
                                    return null;
                                  }
                                  iframeSrc = `https://www.youtube.com/embed/${videoId}`;
                                } else if (video?.slug === "facebook") {
                                  iframeSrc = `https://www.facebook.com/plugins/video.php?href=${url}`;
                                }

                                return (
                                  <Grid lg={4} md={6} sm={12} p={1}>
                                    <Card
                                      component="li"
                                      sx={{
                                        minWidth: 300,
                                        flexGrow: 1,
                                        background: "none !important",
                                        border: "none !important",
                                      }}
                                    >
                                      <CardCover sx={{ position: "relative" }}>
                                        <iframe
                                          width="560"
                                          height="315"
                                          src={iframeSrc}
                                          title={video?.description}
                                          frameborder="0"
                                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          style={{
                                            display: "block",
                                            zIndex: 1,
                                            borderRadius: "0",
                                          }}
                                        ></iframe>
                                      </CardCover>
                                      <CardContent sx={{ textAlign: "center" }}>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: "#555",
                                            padding: "1rem 0",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "normal",
                                            mb: 2,
                                          }}
                                        >
                                          {video?.description}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                );
                              } catch (error) {
                                console.error(
                                  "Error processing video URL:",
                                  video.url,
                                  "| Error:",
                                  error
                                );
                                return null; // Skip the video if there's an error
                              }
                            })
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                color: "#555",
                                marginTop: "1rem",
                              }}
                            >
                              Currently, there are no event videos. Please check
                              in the future for all updated event videos.
                            </p>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="container-fluid gallery-bg py-5">
                      <div className="container pt-4">
                        <Grid container justifyContent="center">
                          {gallery.list?.length > 0 ? (
                            <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                              {gallery.list?.map((event) => (
                                <Link
                                  key={event.slug}
                                  component="div"
                                  onClick={() => setSlug(event.slug)}
                                >
                                  <div className="gallery-background-image">
                                    <img src={event.image} alt="" />
                                  </div>
                                  <Typography variant="h5" textAlign="center">
                                    {event.name}
                                  </Typography>
                                </Link>
                              ))}
                            </Masonry>
                          ) : (
                            "Currently there are no events gallery, please check in future for all updated event gallery."
                          )}
                        </Grid>
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

export default VideoPlaylist;
